var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "createApp" }, [
    _c("div", { staticClass: "content" }, [
      _c(
        "div",
        { staticClass: "searchWrapper" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticStyle: { width: "440px" },
              attrs: {
                "label-position": "right",
                "label-width": "120px",
                model: _vm.formInline,
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "应用名", prop: "applicationName" } },
                [
                  _c("el-input", {
                    attrs: { type: "text", placeholder: "请输入应用名" },
                    model: {
                      value: _vm.formInline.applicationName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInline, "applicationName", $$v)
                      },
                      expression: "formInline.applicationName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "应用包名", prop: "productId" } },
                [
                  _c("el-input", {
                    attrs: { type: "text", placeholder: "请输入应用包名" },
                    model: {
                      value: _vm.formInline.productId,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInline, "productId", $$v)
                      },
                      expression: "formInline.productId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "平台", prop: "platform" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { filterable: "" },
                      model: {
                        value: _vm.formInline.platform,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formInline,
                            "platform",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formInline.platform",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "Android", value: "0" },
                      }),
                      _c("el-option", { attrs: { label: "IOS", value: "1" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "应用描述", prop: "applicationDesc" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      placeholder: "请输入应用描述",
                      maxlength: 100,
                      autosize: { minRows: 4, maxRows: 8 },
                    },
                    model: {
                      value: _vm.formInline.applicationDesc,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInline, "applicationDesc", $$v)
                      },
                      expression: "formInline.applicationDesc",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "center", "margin-top": "100px" } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitData } },
                [_vm._v("创建")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "info" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("dialogShow", false)
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }